<template>
  <div>
    <section class="collection" id="store2">
      <Navbar></Navbar>

      <div class="collection-wrapper">
        <h1 class="new">Shop</h1>

        <div class="collection-grid grid-justified" v-bind:class="{
          'collection-grid grid-justified': watchModelsList && watchModelsList.length > 1,
          'collection-grid grid-1 grid-justified': !watchModelsList || watchModelsList.length == 1
        }">
          <div v-for="(model, index) in watchModelsList" :key="model.id" class="collection-box">
            <a :href="getModelLink(model.name)" target="_blank">
              <img class="model-img" :src="model.assets.front" alt="First Edition Watch" />
            </a>
            <div v-if="!loggedIn" class="duke-dash dd-sm"></div>
            <div class="model-logo-container"></div>
            <img class="model-logo" :src="model.assets.logo" alt="First Edition Logo" />
            <p v-if="loggedIn" class="p1">Limited Edition {{ model.edition }}</p>
            <div v-if="loggedIn" class="duke-dash dd-sm"></div>

            <div v-if="loggedIn">
              <p class="p2">{{ getModelPrice(model) }}</p>

              <!-- buy -->
              <div class="jbtn-metro-box jbtn-shop" v-if="model.status == $C.STATUS.WATCH_MODEL_AVAILABILITY.BUY"
                @click="submit(index)" :disabled="!selectedPrice || isLoading">
                <span v-if="isLoading" key="spinner"><i class="fad fa-spinner-third fa-spin"></i></span>
                <span v-else key="button">Buy</span>
              </div>

              <!-- deposit -->
              <div class="jbtn-metro-box jbtn-shop"
                v-else-if="model.status == $C.STATUS.WATCH_MODEL_AVAILABILITY.WAITING_LIST" @click="submit(index)"
                :disabled="!selectedPrice || isLoading">
                <span v-if="isLoading" key="spinner"><i class="fad fa-spinner-third fa-spin"></i></span>
                <span v-else key="button">Deposit</span>
              </div>

              <!-- inquiry -->
              <div class="jbtn-metro-box jbtn-shop" v-else-if="model.status == $C.STATUS.WATCH_MODEL_AVAILABILITY.INQUIRY"
                @click="submitInquiry(index)" :disabled="!selectedPrice || isLoading">
                <span v-if="isLoading" key="spinner"><i class="fad fa-spinner-third fa-spin"></i></span>
                <span v-else key="button">Inquiry</span>
              </div>

              <!-- out of stock -->
              <div class="jbtn-metro-box jbtn-shop"
                v-else-if="model.status == $C.STATUS.WATCH_MODEL_AVAILABILITY.OUT_OF_STOCK">
                <span>Out of stock</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- INFO MODAL -->
    <b-modal :hide-header="true" :hide-footer="true" id="countries-modal" ref="countries-modal" centered size="lg">
      <!-- Modal content-->
      <div class="jmodal-content">
        <!-- top -->
        <div class="jclose-float" @click="$bvModal.hide('countries-modal')">
          <!-- <span aria-hidden="true">&times;</span> -->
          <i class="fal fa-times "></i>
        </div>
        <div class="jmodal-header">
          <!-- logo -->
          <a class="nav-brand" href="/">
            <img src="@/assets/logo/duke-logo-white.png" alt="" />
          </a>
        </div>

        <!-- main -->
        <div class="jmodal-main duke-style">
          <!-- title big -->
          <div class="jmodal-title">
            <h1 id="jmodal-icon"><i class="fal fa-exclamation-circle"></i></h1>
            <!-- <h1>Delivery not possible</h1> -->
            <!-- <h1>Delivery info</h1> -->
            <h5>PLEASE READ THE INFORMATION BELOW</h5>
          </div>

          <p class="info-modal-content">
            <small>It seems that our Online Shop isn't able to deliver the Watch to your Country.</small>
          </p>
          <p class="info-modal-details text-center">
            <small> Please change your delivery address to one of the following countries:</small>
          </p>
          <p class="info-modal-details"></p>

          <div class="countries-grid">
            <p class="info-modal-details">
              <country-flag country="bh" size="small" /> <small>Bahrain</small>
            </p>
            <p class="info-modal-details"><country-flag country="ca" size="small" /> <small>Canada</small></p>
            <p class="info-modal-details">
              <country-flag country="eu" size="small" /> <small>European Union</small>
            </p>
            <p class="info-modal-details">
              <country-flag country="hk" size="small" /> <small>Hong Kong</small>
            </p>
            <p class="info-modal-details"><country-flag country="in" size="small" /> <small>India</small></p>
            <p class="info-modal-details"><country-flag country="jp" size="small" /> <small>Japan</small></p>
            <p class="info-modal-details"><country-flag country="kw" size="small" /> <small>Kuwait</small></p>
            <p class="info-modal-details"><country-flag country="mo" size="small" /> <small>Macao</small></p>
            <p class="info-modal-details"><country-flag country="mx" size="small" /> <small>Mexico</small></p>
            <p class="info-modal-details"><country-flag country="om" size="small" /> <small>Oman</small></p>
            <p class="info-modal-details"><country-flag country="qa" size="small" /> <small>Qatar</small></p>
            <p class="info-modal-details">
              <country-flag country="sa" size="small" /> <small>Saudi Arabia</small>
            </p>
            <p class="info-modal-details">
              <country-flag country="sg" size="small" /> <small>Singapore</small>
            </p>
            <p class="info-modal-details">
              <country-flag country="ch" size="small" /> <small>Switzerland</small>
            </p>
            <p class="info-modal-details">
              <country-flag country="ae" size="small" /> <small>United Arab Emirates</small>
            </p>
            <p class="info-modal-details">
              <country-flag country="gb" size="small" /> <small>United Kingdom</small>
            </p>
            <p class="info-modal-details">
              <country-flag country="us" size="small" /> <small>United States</small>
            </p>
          </div>

          <div class="jbtn-container-center mt-5">
            <router-link :is="!profileRouteName ? 'span' : 'router-link'" :to="{ name: getProfileRouteName() }"
              class="jbtn jbtn-white jbtn-long"><i class="fal fa-truck"></i> Edit delivery address</router-link>
          </div>

          <p class="info-modal-details text-center">
            <small>Or be contacted by DUKE to add your country, by</small><br>
            <input type="radio" class="mt-2" v-model="contactMethod" :value="$C.CONTACT_TYPE.EMAIL" /> Email
            <input type="radio" class="ml-3" v-model="contactMethod" :value="$C.CONTACT_TYPE.PHONE" /> Phone
            <input type="radio" class="ml-3" v-model="contactMethod" :value="$C.CONTACT_TYPE.WHATSAPP" /> WhatsApp
          </p>

          <div class="jbtn-container-center mt-4">
            <button @click="sendContactRequest" class="jbtn jbtn-dark jbtn-long">
              <span v-if="isSending" key="spinner"><i class="fad fa-spinner-third fa-spin"></i></span>
              <span v-else-if="isSentContactRequest" key="sent"><i class="fal fa-check"></i> Request sent</span>
              <span v-else key="button"><i class="fal fa-comment"></i> Request contact</span>
            </button>
            <!-- <router-link
              :to="{ name: 'Contact' }"
              class="jbtn jbtn-dark jbtn-long"
              ><i class="fal fa-comment"></i> Request contact</router-link
            > -->
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import C from "@/const";
import toast from "@/assets/js/toast";
import Navbar from "@/components/navigation/NavbarPublic";
import firebase from "@/firebase/firebaseInit";
const auth = firebase.auth;
const db = firebase.db;

export default {
  name: "Shop",
  components: {
    Navbar,
    CountryFlag
  },
  data() {
    return {
      watchModelsList: [],

      isLoading: false,
      isSending: false,
      isSentContactRequest: false,
      profileRouteName: null,
      selectedPrice: "",
      // TODO: after determinating the final flow & URL, set the right one for testing & production
      successURL: C.CLUB_URL + "owner/watches",
      cancelURL: C.CLUB_URL + "shop",
      isOwner: false,
      userId: "",
      userEmail: "",

      contactMethod: 0,

      listenerRegistrations: [],
    };
  },
  methods: {
    async init() {
      // check for user being logged in and data is laoded - otherwise make sure the sure is updated
      if (!auth.currentUser && this.loggedIn) {
        await this.$store.dispatch("updateUser", null);
        return;
      }
      if (!auth.currentUser || (auth.currentUser && this.$store.getters.getUser)) {
        // TODO: maybe more use cases to check and make sure user in store is up-to-date
        // => best to do in a refactored way by using global snapshotlistener & and use global component-logic
        return;
      }

      // user needs to be updated in store
      // 1. auth & claims
      this.$store.dispatch("updateUser", auth.currentUser);
      // 2. user data from database
      this.userId = auth.currentUser.uid;
      this.userEmail = auth.currentUser.email;
      db.collection(C.COLLECTION.USERS)
        .doc(this.userId)
        .get()
        .then(doc => {
          const userData = doc.data();
          userData.id = doc.id;
          this.$store.commit("setUser", doc.data());
          if (doc.data().type >= 2) {
            this.isOwner = true;
          }
        });
    },
    //load all watch models list
    allActiveWatchModels: function () {
      const registration = db.collection(C.COLLECTION.WATCH_CATALOG)
        .where("active", "==", true)
        .onSnapshot(querySnapshot => {
          this.watchModelsList.splice(0);
          querySnapshot.forEach(doc => {
            const docdata = doc.data();
            docdata.id = doc.id;
            this.watchModelsList.push(docdata);
          });
        });
      this.listenerRegistrations.push(registration);
    },
    getWatchModel: function () {
      db.collection(C.COLLECTION.WATCH_CATALOG)
        .doc(this.modelId)
        .get()
        .then(doc => {
          const docdata = doc.data();
          docdata.id = doc.id;
          this.model = docdata;
        })
        .catch(error => {
          console.log("Error getting WATCH MODEL document: ", error);
        });
    },

    //TODO: deactivate buttons onClick to avoid multiple payments processes
    async submit(index) {

      if (!auth.currentUser || !this.userDoc || auth.currentUser.uid != this.$store.getters.getAuthUser.uid) {
        toast.error("Not logged in");
        this.$router.replace({ name: "Login" });
        return;
      }

      if (!auth.currentUser.emailVerified) {
        toast.error("Please verify your email first!");
        return;
      }

      if (this.isLoading) return;

      this.isLoading = true;

      // make sure user 100% up to date in order to avoid any issue with firebase caching and outdated sessions
      try {
        // not sure if necessary if we have properyl set up onAuthStateChanged + snapshotlistener
        await auth.currentUser.getIdTokenResult(true);
        if (auth.currentUser.uid != this.$store.getters.getAuthUser.uid) {
          toast.error("Authentication error, please log in again.");
          this.isLoading = false;
          return;
        }
      } catch (error) {
        toast.error("Authentication error, please log in again.");
        this.isLoading = false;
        return;
      }

      // check if we have country code
      if (!this.userDoc || !this.userDoc.delivery || !this.userDoc.delivery.countryCode) {
        this.openInfoDialog();
        this.isLoading = false;
        return;
      }

      const countryCode = this.userDoc.delivery.countryCode;
      var taxRef = db
        .collection(C.COLLECTION.PRODUCTS)
        .doc(C.COLLECTION.TAX_RATES)
        .collection(C.COLLECTION.TAX_RATES)
        .where("livemode", "==", C.STRIPE_LIVE_MODE)
        .where("active", "==", true)
        .where("country", "==", countryCode);
      // check if we have state code if needed
      if (countryCode == "CA" || countryCode == "US") {
        const stateCode = this.userDoc.delivery.stateCode;
        if (!stateCode) {
          this.openInfoDialog();
          this.isLoading = false;
          return;
        }

        taxRef = taxRef.where("state", "==", stateCode);
      }

      var taxRateQuerySnap;
      try {
        taxRateQuerySnap = await taxRef.get();
        if (!taxRateQuerySnap || taxRateQuerySnap.empty) {
          this.openInfoDialog();
          this.isLoading = false;
          return;
        }
      } catch (error) {
        this.isLoading = false;
        toast.error(error);
        return;
      }

      var taxRateId = taxRateQuerySnap.docs[0].id;
      var taxRatePercent = taxRateQuerySnap.docs[0].data().percentage;
      // if there are mor rates, make sure to have the most recent one
      if (taxRateQuerySnap.docs.length > 1) {
        var created = 0;
        taxRateQuerySnap.docs.forEach(taxRateDoc => {
          const tax = taxRateDoc.data();
          tax.id = taxRateDoc.id;
          if (created < tax.created) {
            taxRateId = tax.id;
            created = tax.created;
            taxRatePercent = tax.percentage;
          }
        });
      }
      // await auth.currentUser.getIdToken

      const selectedWatchModel = this.watchModelsList[index];
      const productID = C.PRODUCT_PREFIX + selectedWatchModel.id;

      // get the stripe prices for the selected watch
      // there should only be one active price
      // => TODO: use firebase functions to set ID of most recent (onCreate) prize in watchCatalog doc instead - also try-catch
      var querySnapshot;
      try {
        querySnapshot = await db
          .collection(C.COLLECTION.PRODUCTS)
          .doc(productID)
          .collection(C.COLLECTION.PRICES)
          .where("active", "==", true)
          .limit(1)
          .get();
      } catch (error) {
        this.isLoading = false;
        toast.error(error);
        return;
      }
      if (!querySnapshot || querySnapshot.empty) {
        toast.error("Watch not available");
        this.isLoading = false;
        return;
      }

      // TODO: stripe does only apply the right tax rate if it is set in tax_rates array of the price item in DB => always need to be up to date
      // resp. we need to build the price item here with tax rate for session => BUT there are probably restrictions to that, otherwise, user could always build their own prie and buy stuff for 1€
      // also always need to check the right tax rate that applies to the buying user and make sure the price supports it, otherise deny purchase
      const priceid = querySnapshot.docs[0].id;
      const selectedPrice = {
        price: priceid,
        quantity: 1,
        tax_rates: [taxRateId]
      };

      // You will be redirected to Stripe's secure checkout page
      const checkoutSession = {
        mode: "payment",
        payment_method_types: ["card"],
        automatic_tax: false,
        tax_id_collection: {
          enabled: true,
        },
        customer: this.userDoc.stripeId,
        customer_update: {
          address: 'never',
          name: 'never',
          shipping: 'auto'
          // phone??????????????????
        },
        phone_number_collection: { enabled: false },
        collect_shipping_address: true,
        allow_promotion_codes: false,
        line_items: [selectedPrice],
        success_url: C.CLUB_URL + "redirection",
        cancel_url: window.location.origin,
        metadata: {
          productType: C.PRODUCT_TYPE.WATCH,
          modelName: selectedWatchModel.name,
          modelId: selectedWatchModel.id,
          taxPercent: taxRatePercent,
          delivery: JSON.stringify(this.userDoc.delivery)
        }
      };

      try {
        // Create checkout session document in database
        const docRef = await db
          .collection(C.COLLECTION.USERS)
          .doc(auth.currentUser.uid)
          .collection(C.COLLECTION.CHECKOUT_SESSION)
          .add(checkoutSession);

        // Wait for the CheckoutSession url to get attached by the extension
        const registration = docRef.onSnapshot(snap => {
          const { error, url } = snap.data();
          if (error) {
            // Show an error to your customer and then inspect your function logs.
            alert(`An error occured: ${error.message}`);
            // document
            //   .querySelectorAll("button")
            //   .forEach((b) => (b.disabled = false));
          }
          if (url) {
            window.location.assign(url);
          }
        });
        this.listenerRegistrations.push(registration);
      } catch (error) {
        this.isLoading = false;
        toast.error(error);
        return;
      }
    },
    getModelPrice(model) {
      if (
        !model ||
        (model.status != C.STATUS.WATCH_MODEL_AVAILABILITY.BUY &&
          model.status != C.STATUS.WATCH_MODEL_AVAILABILITY.WAITING_LIST)
      )
        return "";

      const formatter = new Intl.NumberFormat("lu-LU", {
        style: "currency",
        currency: "EUR"
        // maximumFractionDigits: 0
      });

      return formatter.format(model.price);
      // return "EUR " + model.price;
    },

    submitInquiry() {
      // TODO
    },

    getModelLink(name) {
      return "https://dukehorlogerie.com/" + C.getModelLink(name);
    },
    openInfoDialog() {
      this.$refs["countries-modal"].show();
    },
    getProfileRouteName() {
      const type = this.$store.getters.getUserTypeString;
      if (type) {
        this.profileRouteName = "Profile" + type;
      } else {
        this.profileRouteName = "";
      }

      return this.profileRouteName;
    },
    sendContactRequest() {
      if (this.isSending || this.isSentContactRequest) return;

      this.isSending = true;

      // trigger backend to send mail to contact
      db.collection(C.COLLECTION.EMAILS).add({
        email: auth.currentUser.email,
        countryCode: this.userDoc.delivery.countryCode,
        type: C.EMAIL_TYPE.DELIVERY_REQUIREMENT,
        userId: auth.currentUser.uid,
        contact: this.contactMethod,
      }).then(() => {
        this.isSentContactRequest = true;
        this.isSending = false;
        toast.success("Your contact request has been sent.");
      }).catch((error) => {
        console.log(error);
        this.isSending = false;
        toast.error("Could not send request. Please check your connection.");
      });
    }
  },
  computed: {
    userDoc: {
      get() {
        return this.$store.getters.getUser;
      }
    },
    loggedIn: {
      get() {
        return auth.currentUser && this.$store.getters.getUserType != C.TYPE.PRESS;
      }
    }
  },
  created() {
    this.init();
    if (!auth.currentUser)
      return;

    const registration = db.collection(C.COLLECTION.USERS)
      .doc(auth.currentUser.uid)
      .onSnapshot(doc => {
        this.$store.commit("setUser", doc.data());
      });
    this.listenerRegistrations.push(registration);
  },
  mounted() {
    this.allActiveWatchModels();
  },
  beforeDestroy() {
    if (this.listenerRegistrations.length > 0) {
      this.listenerRegistrations.forEach(registration => {
        registration();
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
